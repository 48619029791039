.rob-swan-coaching {
  font-size: 4rem;
  margin: 3rem 1rem 0rem 1rem;
  font-family: 'Montserrat, sans-serif';
  font-weight: bold;
  color: black; /* Change color here */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-item {
  flex: 1;
  cursor: pointer;
  text-align: center;
  background-color: #456685;
  color: #f1f1f1;
  padding: 0.5em;
  margin: 0.5em;
  font-family: 'Lato', sans-serif; /* Apply Lato font */
  transition: background-color 0.3s ease;
  font-weight: lighter;
}

.nav-item:hover {
  background-color: rgba(69, 102, 133, 0.6); /* 60% transparency on hover */
}

